.pricing-page {
    min-height: 90vh;
}

.pricing-page > .header {
    min-height: 200px;
    position: relative;
    background-color: #2A2A2A;
}

.pricing-page > .header > .center-info {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #FFFFFF;
}

.pricing-page > .header > .center-info > h2 {
    color: #FFFFFF;
}

.pricing-page > .main-body {
    padding: 60px;
}

.pricing-page > .main-body > .tabs > .tab-header {
    display: flex;
    border: 1px solid #D8D8D8;
    border-radius: 5px;
}

.pricing-page > .main-body > .tabs > .tab-header > .tab {
    width: 50%;
    text-align: center;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 20px;
    transition: 0.2s all;
}

.pricing-page > .main-body > .tabs > .tab-header > .tab.active {
    background-color: #DADADA;
}

.pricing-page > .main-body > .tabs > .tab-header > .tab.active::after {
    width: 100%;
}

.pricing-page > .main-body > .tabs > .tab-content {
    padding-top: 40px;
}

.pricing-page > .main-body > .tabs > .tab-content > .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 35px;
}

.pricing-page > .main-body > .tabs > .tab-content > .grid > .plan {
    max-width: 400px;
    border: 1px solid #E6E6E7;
}

.pricing-page > .main-body > .tabs > .tab-content > .grid > .plan.pay-as-go > .header {
    background-color: #D2F1F1;
}

.pricing-page > .main-body > .tabs > .tab-content > .grid > .plan.silver > .header {
    background-color: #A5B0B5;
    color: #FFFFFF;
}

.pricing-page > .main-body > .tabs > .tab-content > .grid > .plan.charcoal > .header {
    background-color: #2A2A2A;
    color: #FFFFFF;
}


.pricing-page > .main-body > .tabs > .tab-content > .grid > .plan.ivory > .header {
    background-color: #FEF6DE;
}

.pricing-page > .main-body > .tabs > .tab-content > .grid > .plan.turquoise > .header {
    background-color: #5CE0EB;
    color: #FFFFFF;
}

.pricing-page > .main-body > .tabs > .tab-content > .grid > .plan.teal > .header {
    background-color: #009BA7;
    color: #FFFFFF;
}

.pricing-page > .main-body > .tabs > .tab-content > .grid > .plan > .header {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #3D5154;
    padding: 20px;
}

.pricing-page > .main-body > .tabs > .tab-content > .grid > .plan > .price {
    padding: 35px 10px;
    text-align: center;
}

.pricing-page > .main-body > .tabs > .tab-content > .grid > .plan > .price > .button {
    background-color: #D2F1F1;
    color: #009BA7;
    border: 1px solid #D2F1F1;
    padding: 0 15px;
    font-size: 14px;
    border-radius: 3px;
}

.pricing-page > .main-body > .tabs > .tab-content > .grid > .plan > .price > h3 {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    color: #009BA7;
}

.pricing-page > .main-body > .tabs > .tab-content > .grid > .plan > .price > h5 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #7A7A7A;
}

.pricing-page > .main-body > .tabs > .tab-content > .grid > .plan > .price > h3 > span {
    font-size: 16px;
    font-weight: bold;
    color: #009BA7;
}

.pricing-page > .main-body > .tabs > .tab-content > .grid > .plan > .divider {
    margin: 0 auto;
    width: 80%;
    height: 2px;
    background-color: #E6E6E7;
}

.pricing-page > .main-body > .tabs > .tab-content > .grid > .plan > .description {
    padding: 40px 25px;
    padding-bottom: 80px;
}

.pricing-page > .main-body > .tabs > .tab-content > .grid > .plan > .description > ul  {
    list-style-image: url('/images/check-mark-button.svg');
    list-style-position: outside;
}

.pricing-page > .main-body > .tabs > .tab-content > .grid > .plan > .description > ul > li  {
    font-size: 16px;
    margin-bottom: 15px;
}

.pricing-page > .main-body > .tabs > .tab-content > .grid > .plan > .button-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.pricing-page > .main-body > .tabs > .tab-content > .grid > .plan > .button-wrapper > .button {
    background-color: #5CE0EB;
    color: #FFFFFF;
    margin: 0 auto;
    width: 70%;
    text-align: center;
    padding: 10px;
    min-width: 200px;
    border-radius: 4px;
}

@media only screen and (max-width: 768px){
    .pricing-page > .main-body > .tabs > .tab-content > .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
        row-gap: 35px;
    }
}

@media only screen and (max-width: 576px){
    .pricing-page > .main-body > .tabs > .tab-content > .grid {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 20px;
        row-gap: 35px;
    }
}