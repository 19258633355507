#footer {
  margin-top: auto;
  position: relative;
  width: 100%;
  min-height: 450px;
  overflow: hidden;
  background-color: #F5F6F8;
}

#footer .top-section {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

#footer .top-section .left-section {
  flex-basis: 40%;
}

#footer .top-section .right-section {
  flex-basis: 50%;
}

#footer .top-section .right-section .footer-grid {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1350px;
  }
}


#footer .top-section .right-section .footer-grid a {
  flex-basis: 30%;
}

#footer .padded-intro-section {
  padding: 30px 0 30px;
}

#footer .padded-intro-section .footer-logo {
  margin-bottom: 30px;
}

#footer .padded-intro-section .h3-newsletter {
  margin-bottom: 10px;
  color: #2A2A2A;
}

#footer .padded-intro-section .p-newsletter {
  margin-bottom: 20px;
  color: #2A2A2A;
}

#footer .padded-intro-section .row {
  justify-content: space-between;
}

#footer .copy-right {
  font-size: 13px;
}

#footer .icon-wrapper {
  background-color: #2A2A2A;
}

#footer .icon-wrapper > svg {
  color: #FFFFFF;
}

.curve-footer {
  /* background-image: url('/images/curve-white.svg'); */
  background-color: #F5F5F5
  ;
  height: 400%;
  width: 400%;
  border-radius: 100%;
  top: 0%;
  left: -150%;
  position: relative;
  z-index: 1;
  min-height: 450px;
}

.footer-grid {
  display: grid;
  justify-items: baseline;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-gap: 2rem 1.2rem;
  margin-bottom: 40px;
}
.footer-grid a {
  color: #2A2A2A;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  padding: .5rem;
}
.footer-logo {
  grid-column: 1/3;
}

.icon-container > img {
  margin-right: 30px;
}

.icon-container > img:last-of-type {
  margin-right: 0;
}

.subscribe-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
}

.subscribe-btn > svg {
  margin-right: 5px;
  font-size: 1.1rem;
}

.line {
  border: 1px solid #D8D8D8;
}
.social-cta {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

@media only screen and (max-width: 768px){
  .social-cta {
    justify-content: center;
  }
  

  .curve-footer .container {
    width: 95vw;
  }
  .footer-grid {
    margin-top: 0;
    grid-gap: .8rem 1.2rem;
  }

}

@media only screen and (max-width: 576px){
  .curve-footer {
    border-radius: 0;
  }

  #footer .top-section {
    flex-direction: column;
  }

  .footer-logo img {
    width: 130px;
  }

  #footer .padded-intro-section .h3-newsletter {
    font-size: 23px;
  }

  #footer .padded-intro-section .p-newsletter {
    font-size: 14px;
  }

  #footer .top-section .right-section .footer-grid {
    justify-content: space-between;
  }

  #footer .top-section .right-section .footer-grid a {
    flex-basis: 45%;
  }

  #footer .padded-intro-section {
    padding: 20px;
  }
}

.close-btn-container {
  display: flex;
  justify-content: flex-end;
}

.close-btn-container > button {
  background: transparent;
  border: none;
}

.header {
  text-align: center;
}

form {
  padding: 20px 40px;
}

.submit-btn-container > button {
  width: 100%;
}

.success-block {
  padding: 40px;
}

.success-block > .header > p {
  margin-bottom: 20px;
}

.form-group > label {
  color: #009BA7;
  font-size: 14px;
  font-weight: 700;
}

.form-group > input {
  border-color: #7A7A7A;
}

.close-btn-container > button > svg {
  font-size: 30px;
  color: rgba(51, 50, 56, 0.52);
}

.footer-grid a > button {
  color: #2A2A2A;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600 !important;
  padding: .5rem;
  background: transparent;
  border: none;
  font-family: 'Muli', sans-serif;
}