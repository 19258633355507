@import url('https://fonts.googleapis.com/css2?family=Muli:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
:root {
  --white: #FFFDFD;
  --black: #2F2E2E;
  --bg: #F8F5F5;
  --off-bg: #FBFBFB;
  --light-primary:#DCE6F5;
  --primary: #5EE1E8;
  --off-primary: #CFF6F8;;
  --primary-dark: #52C1C8;
  --primary-darker: #009BA7;
  --off-green: #3D5154;
  --grey: #626161;
  --off-grey: #E9F3FE;
  --webkit-focus-ring-color: #5EE1E8;
  --error: #dc3545;
  --noti: #EB5757;
}
html {
  font-size: 100%;
  font-display: swap;
  scroll-behavior: smooth;
}
body {
  background-color: var(--bg);
  font-family: 'Muli', sans-serif;
}

span{
  color: var(--primary);
}
a, a:active, a:hover {
  color: currentColor;
  background-color: transparent;
  cursor: pointer;
}
button {
  font-weight: 700 !important;
}
h1,h2,h3 {
  font-weight: 700;
  font-style: normal;
}
h4,h5,h6 {
  font-weight: 600;
  font-style: normal;
}
h1 {
  font-size: 45px;
  line-height: 116.5%;
}
h2 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  color: #330033;
}
.serif {
  font-family: 'Montserrat', sans-serif !important;
}
h3 {
  font-family: "Montserrat" , sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 48px;
  letter-spacing: -1px;
}
h5 {
  font-size: 22px;
  line-height: 28px;
}
h6 {
  font-size: 18px;
  line-height: 23px;
  font-weight: bold;
  color: #303030;
}
p {
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  margin: 0;
}
.pointer {
  cursor: pointer;
}
.btn-primary-darker {
  background-color: var(--primary-darker);
}

.btn-outline-primary-darker, .btn-outline-primary-darker:link {
  border: 2px solid var(--primary-darker);
  color: #000;
}

.btn-outline-primary-darker:hover, .btn-outline-primary-darker:active {
  border: 2px solid var(--primary-darker);
  background-color: var(--primary-darker);
  color: #fff;
}

.icn-circle {
  position: relative;
  text-align: center;
  border-radius: 50%;
  height: 38px;
  width: 38px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
}
.icn-circle.primary-dark {
  background: var(--primary-darker);
  color: var(--white);
}
.icn-circle img {
  object-fit: cover;
}
.vl {
  border-left: 1px solid #E0E0E0;
  height: 25px;
  margin: 0 1rem;
}
/********* Custom Select data url *********/ 
.custom-select {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItY2hldnJvbi1kb3duIj48cG9seWxpbmUgcG9pbnRzPSI2IDkgMTIgMTUgMTggOSI+PC9wb2x5bGluZT48L3N2Zz4=");
  background-size: 16px;
}
.btn-link {
  font-weight: 600;
  color: var(--primary);
  text-decoration: none;
}
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  color: var(--primary-dark);
}
.text-grey {
  color: var(--grey); 
}
.text-primary {
  color: var(--primary) !important;
}
.text-teal {
  color: var(--primary-darker) !important;
}
.btn-padding {
  padding: .6rem 1.2rem;
}
.btn-padding--md {
  padding: .7rem 2.8rem;
}
.btn-padding--lg {
  padding: .75rem 5.75rem;
}
.primary-bg {
  background-color: var(--primary);
}
.bg-light-white {
  background-color: #FFFCFC;
}
.primary-dark-color {
  color: var(--primary-dark);
}
.btn-primary {
  background-color: var(--primary);
  color: var(--black);
  border: none;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled):active {
  background-color: rgba(82, 193, 200, .95);
}
@media screen and (max-width: 769px) {
  .btn-padding {
    padding: .4rem .9rem;
  }
  .w-75 {
    width: 95% !important;
  }
}
.badge {
  padding: .4.475rem .8rem;
  border-radius: 2px;
}
.badge-light {
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
}
.form-control { 
  height: calc(1.5em + 1.2rem + 2px);
  padding: .6rem 1.2rem;
  border: 1px solid rgba(82, 193, 200, 0.5);
}
.form-control.light {
  border: 1.5px solid #EEEEEE;
}
.form-control.error {
  border: 1.5px solid var(--error) !important;
}
.form-control:focus {
  box-shadow: 0 0 0 2px rgba(82, 193, 200, 0.5);
}
.input-group-text {
  background-color: #FFF;
  border: 1.5px solid #EEEEEE;
}
.center-hr {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.padding-2x {
  padding: 2rem 1rem;
}
.layout-container {
  padding: 63px 0 0;
  overflow: hidden;
  overflow-y: auto;
}
.auth-container {
  position: relative;
  margin-top: 64px;
  margin-left: 82px;
  padding: 12px;
  max-width: calc(100vw - 82px);
  height: auto;
  overflow: hidden;
}
.tutor-auth-container {
  position: relative;
  overflow: hidden;
  margin-block-start: 110px;
  min-height: calc(100vh - 110px);
  max-width: 100vw;
  padding: 24px 24px 24px 40px;
}
@media only screen and (max-width: 576px) {
  .auth-container {
    margin-left: 0;
    max-width: calc(100vw - 0px);
  }
  .tutor-auth-container {
    padding: 32px 24px;
    margin-block-start: 60px;
  }
}
main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.middle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.middle-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.white-curve {
  position: absolute;
  top: -2.65rem;
  left: 0;
  width: 100%;
}
.white-curve svg {
  width: 100%;
}

.icn-cirle {
  width: 28px;
  height: 28px;
  border-radius: 30px;
  color: var(--white);
  font-weight: 900;
  background-color: var(--primary);
  margin: 0 20px 0 0;
  display: grid;
  place-content: center;
}

.hero-bg {
  position: relative;
  background: url('/images/home-banner.svg') no-repeat;
  background-size: cover;
  background-position: top center;
  min-height: 60vh;
}
.hero-bg--about {
  position: relative;
  background: url('/images/FAQ-header.jpg') no-repeat;
  background-size: cover;
  background-position: top center;
  max-height: 40vh;
}
@media only screen and (max-width: 768px ) {
  .hero-bg--about {
    min-height: 25vh;
    max-height: 35vh;
  }
}
.padded-intro-section {
  padding: 120px 0 30px;
}
.intro-content {
  width: min(510px, 90%);
  margin: 0 auto;
  color: white;
}
.intro-content label {
  color: #333;
}
.cta-section {
  width: min(323px, 100%);
  margin: 0 auto;
}
.link-cta {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

@media screen and (max-width: 769px) {

  .padded-intro-section {
      padding: 90px 16px 60px;
  }
  .indro-content h1{
    font-size: 32px;
  }
}

/************* Login ***********/ 
.bg-bubble {
  position: relative;
  background: url('/images/bubble.png') no-repeat;
  background-size: 167px 332px;
  background-position: bottom left;
  z-index: 2;
}

@media only screen and (max-width: 576px) {
  .bg-bubble {
    background: none;
  }
}

/************* Signup ***********/ 
.signup-grid {
  display: grid;
  grid-template-columns: 1fr 2.1fr;
  grid-gap: 6rem;
  padding: 2.5rem 0;
}
.signup-grid.--tutor {
  grid-template-columns: 1fr 1.6fr;
}
.bg-img-block {
  background-color: #ccc;
  position: absolute;
  width: 42vw;
  height: 703px;
  z-index: 1;
  background-image: linear-gradient(to bottom, rgba(16, 25, 26, 0.4), rgba(16, 25, 26, 0.4)), url('/images/tutor-step-1.png');
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.signup-grid.--onlyform {
  grid-template-columns: 1fr;
  width: min(600px, 100%);
  margin: 0 auto;
  grid-gap: 2rem;
}
.heading-content {
  padding: 8rem 0 0;
  z-index: 2;
}
.heading-content img{
  float: right;
}
@media only screen and (max-width: 768px){
  .signup-grid {
    grid-template-columns: 1fr;
    grid-gap: 3.5rem;
    padding: 2.5rem 1.112rem;
  }
  .signup-grid.--tutor {
    grid-template-columns: 1fr;
  }
  .heading-content {
    padding: 1rem 0;
  }
  .bg-img-block {
    width: 100%;
    height: 390px;
  }
}

.small-title {
  font-family: inherit;
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  color: #999999;
}

.signup-step-nav {
  width: min(505px, 100%);
  margin: 0 auto;
}
.tutor-signup-step-nav {
  padding: 0 5rem;
  margin: 2rem auto 0 auto;
}
.signup-step-nav ul {
  margin: 0px; 
  list-style-type: none; 
  padding-left: 0px;
  display: flex;
  justify-content: center;
}
.signup-step-nav ul li {
  padding: 22px 0px;
  margin-right: 199px; 
  position: relative; 
  display: inline-block;
}
.signup-step-nav ul li:last-child {margin-right: 0px;}
.signup-step-nav ul li:after {
  position: absolute; 
  content: ''; 
  width: 200px;
  height: 1px;
  z-index: -1;
  background: #C6C2C2; 
  left: 29px; 
  top: 37px;
}
.signup-step-nav ul li:last-child:after {width: 0;}
.signup-step-nav ul li span {
  display: flex;
  justify-content: center;
  align-items: center; 
  border: 1px solid #C5C7C7;
  background-color: #C5C7C7; 
  opacity: .88;
  border-radius: 50%; 
  width: 30px; 
  height: 30px;
  color: #C5C7C7; 
  font-weight: 600;
}
.signup-step-nav ul li.active span {
  background: #5EE1E8;
  border: 1px solid #5EE1E8;
  color: #FFF;
  transform: scale(1.5);
}
.txt-14 {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #838383;
}
@media only screen and (max-width: 576px) {
  .signup-step-nav ul li {
    margin-right: 99px;
  }
  .signup-step-nav ul li:after {
    width: 100px;
  }
  .tutor-signup-step-nav {
    padding: 0 1rem;
  }
}

#signup-form, .additional-section {
  margin: 2.5rem auto;
  width: min(590px, 100%);
}
/*** CHECK BOX CUSTOM ***/
.custom-control-label::before {
    position: absolute;
    top: 3px;
    left: -1.2rem;
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #7CE6EB solid 3px;
    border-radius: 5px;
    cursor: pointer;
}
.custom-control-label.error::before {
  border: 3px solid var(--error);
}
.custom-control-label::after {
    position: absolute;
    top: 6px;
    left: -12px;
    display: block;
    content: "";
    cursor: pointer;
    
}
input[type="checkbox" i]:focus,
input[type="radio" i]:focus {
  outline-offset: 0px;
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #7CE6EB;
    border-color: #7CE6EB;
    background-color: #FFF;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: none;
    width: 5px;
    height: 10px;
    border: solid #7CE6EB;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.custom-control-label {
  cursor: pointer;
  padding-left: 0.56rem;
  font-size: 1rem;
  padding-top: 0;
}
/************* CUSTOM RADIO ****************/

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background: var(--primary);
  width: 9px;
  height: 9px;
  border-radius: 6px;
}
.custom-radio .custom-control-label::before {
  content: "";
  position: absolute;
  top: 3px;
  left: -1rem;
  background-color: #fff;
  border: #7CE6EB solid 2px;
}

.custom-radio .custom-control-label::after {
  content: "";
  position: absolute;
  top: 8px;
  left: -11px;
}
@media only screen and (max-width: 768px) {
  .custom-radio .custom-control-label::before {
    top: 4px;
  }
  .custom-radio .custom-control-label::after {
    top: 9px;
  }
}
/************* File Upload Component *******/ 
.file-upload-component {
  width: min(550px, 90%);
  margin: 2rem auto;
  display: grid;
  place-items: center;
}
.upload-btn {
  background: #EEEEEE;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  padding: .75rem 3rem .75rem 1.5rem;
}
.upload-btn * {
  cursor: pointer;
}
.upload-btn label {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #686868;
}
.dropzone {
  border: 2px dashed var(--grey);
  padding: 2rem 1rem;
  height: 130px;
}
@media only screen and (max-width: 768px) {
  .dropzone {
    height: 100%;
  }
}

/************* Signup Tutor related ************/
.select-day-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem .5rem;
  grid-gap: 1rem .5rem;
}
.select-subject-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  gap: 1rem .5rem;
  grid-gap: 1rem .5rem;
}
.subject-item > label {
  position: relative;
}
.subject-item > label::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  height: 4px;
  width: 100%;
  background-color: var(--primary);
}

/********** React Select Override *******/

.basic-multi-select.error .css-yk16xz-control {
  border: 1.5px solid var(--error) !important; }

.css-yk16xz-control {
  border-color: #eee !important; }

.css-1pahdxg-control {
  border-color: var(--primary-dark) !important;
  box-shadow: 0 0 0 1px var(--primary-dark) !important; }
.css-1pahdxg-control:hover {
  border-color: var(--primary-dark) !important; }

/************* Contact Page ***************/
.header-banner {
  position: relative;
  background-color: var(--primary);
  background-size: cover;
  background-position: top center;
  min-height: 25vh;
}

.header-banner.small {
  min-height: 20vh;
  height: 20vh;
}

.nav-margin {
  margin-top: 63px;
}

.locate-tutor {
  margin: 3rem 0;
}

.card.--locate {
  border-radius: 6px;
  border: 1px solid rgba(#1c1c1c, .3);
}

/*********** FAQ & TERMS PAGE **********/

.header-banner--img {
  position: relative;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0,0, 0.3)),url('/images/terms-header.jpg') no-repeat;
  background-size: cover;
  background-position: top center;
  min-height: 30vh;
  width: 100%;
}

.header-banner--img.faq {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0,0, 0.3)),url('/images/FAQ-header.jpg') no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-text-blur {
  color: var(--white);
  opacity: 0.2;
  width: max-content;
  max-width: 100vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bg-text-blur.--about {
  top: 90px;
  left: unset;
  float: right;
  right: 0;
  color: #000;
  opacity: 0.09;
  transform: translate(-40%, -65%);
}

.bg-text-blur h1 {
  font-family: inherit;
  font-style: normal;
  font-weight: 800;
  font-size: 62px;
  margin: 0 0 1rem 0;
  line-height: 1.2;
}

@media only screen and (max-width: 768px) {
  .bg-text-blur h1 {
    font-family: inherit;
    font-style: normal;
    font-weight: 700;
    font-size: 52px;
    line-height: 65px;
  }
  .bg-text-blur.--about {
    top: 69px;
    left: 0;
    float: unset;
    right: unset;
    color: #000;
    opacity: 0.09;
    transform: translate(10%, -65%);
  }
}
@media only screen and (max-width: 480px) {
  .bg-text-blur h1 {
    display: none;
  }
  .bg-text-blur.--about h1{
    display: block;
  }
}

.text-container {
  width: min(880px, 88%);
  margin: 0 auto;
}

.collapse-card {
  border: none;
  margin: 1.6rem 1rem;
  border-radius: 14px;
  background-color: #F5F5F5;
}
.collapse-card .card-header {
  border-bottom: 0;
  background-color: inherit;
}
.accordion>.card:not(:first-of-type),
.accordion>.card:not(:last-of-type){
  border-radius: 14px;
}

/************* FIND TUTOR SECTION ************/
.tutor-collage-image {
  float: right;
  height: 550px;
  width: auto;
  object-fit: contain;
}
@media only screen and (max-width: 768px) {
  .tutor-collage-image {
    float: none;
    width: 100%;
    height: auto;
  }
}

.bg-text--tutor {
  color: var(--black);
  opacity: 0.2;
  width: max-content;
  max-width: 100vw;
  margin: 10px auto;
}
.bg-text--tutor h1 {
  font-family: inherit;
  font-style: normal;
  font-weight: 800;
  font-size: 62px;
  margin: 0 0 1rem 0;
  line-height: 1.2;
  letter-spacing: 2px;
}

.tutor-article {
  position: relative;
  margin: 20px auto 70px;
  border-top: 0.5px solid #AFAFAF;
  border-radius: 0;
  min-height: 234px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  background-color: var(--white);
  padding: 21px;
}
.tutor-article:last-child {
  margin-bottom: 0;
}
.tutor-article img {
  border-radius: 8px;
  width: 133px;
  height: 190px;
  object-fit: cover;
  border: 1px solid #E9EFEF;
}
.tutor-details {
  flex-grow: 1;
  position: relative;
  width: 100%;
}
.top-section {
  padding: 0 1rem;
}
.bottom-section {
  background: #E9EFEF;
  border-radius: 8px;
  width: 100%;
  padding: 1rem 2.2rem 11px 25px;
}
.online-badge {
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background-color: #1CED18;
}
.money-badge {
  width: 22px;
  height: 22px;
  border-radius: 20px;
  background-color: #FFD55C;
  background-image: url("data:image/svg+xml,%3Csvg width='9' height='15' viewBox='0 0 9 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.06167 6.67497C5.85183 6.60945 5.63742 6.54111 5.42325 6.47005V3.34928C5.92071 3.47217 6.21825 3.69048 6.25304 3.71695L6.87641 4.22443L8.03624 3.11466L7.40751 2.60285C7.36303 2.56672 6.62779 1.98274 5.42325 1.8057V0.442688H3.71257V1.86156C3.61415 1.88189 3.5147 1.90413 3.41332 1.93111C2.27093 2.23487 1.41742 3.09695 1.18602 4.18105C0.974579 5.17166 1.33576 6.12804 2.12883 6.67699C2.53323 6.9569 3.03332 7.21346 3.71268 7.4829V11.4927C3.1428 11.447 2.76109 11.3116 2.12689 10.9456L1.41114 10.5323L0.474609 11.7958L1.19036 12.209C2.1749 12.7774 2.85518 12.9567 3.71268 13.0064V14.4427H5.42336V12.9433C7.17007 12.616 8.19123 11.3341 8.41954 10.1353C8.71709 8.57294 7.7915 7.21466 6.06167 6.67497ZM3.18454 5.48901C2.90034 5.29224 2.7758 4.88863 2.86715 4.46066C2.94585 4.09177 3.20906 3.65334 3.71268 3.44338V5.79508C3.51607 5.69645 3.33702 5.59459 3.18454 5.48901ZM6.73248 9.88492C6.62128 10.4684 6.17685 11.0871 5.42336 11.3641V8.07684C5.44639 8.08408 5.46829 8.09113 5.49167 8.09837C6.87652 8.53047 6.79144 9.57512 6.73248 9.88492Z' fill='%23AD7912'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.badge-primary {
  color: #0D5155;
  background-color: rgba(94, 225, 232, 0.2);
  border-radius: 10px;
  padding: 5px 25px;
}

@media only screen and (max-width: 768px) {
  .tutor-article {
    position: relative;
    margin: 100px auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: .65rem;
  }
  .tutor-article img {
    margin-top: -30%;
    height: 190px;
    width: auto;
  }
  .top-section {
    padding: 1rem .65rem;
  }
  .bottom-section {
    position: relative;
    padding: 20px 1rem;
  }
}

/********* Pagination *************/
.pagination {
  width: max-content;
  margin: 80px auto;
  border-radius: 5px;
}
.page-link {
  margin: 0 10px;
  border-radius: 5px;
  background-color: #979797;
  color: var(--white) !important;
  font-family: 'Montserrat';
  padding: 10px 14px;
  font-size: 18px;
  line-height: 1;
  font-weight: 900;
}
.page-link:hover {
  z-index: 2;
  color: var(--white) !important;
  text-decoration: none;
  background-color: var(--primary);
  border-color: var(--primary);
}
.page-item.active .page-link {
  z-index: 3;
  background-color: var(--primary);
  border-color: var(--primary);
}


.filters-card {
  box-shadow: 0 0 2px rgba(0, 0, 0 , .1);
  margin: 1.6rem 1rem;
  border-radius: 12px;
  background-color: var(--white);
}
.filters-card .card-header {
  background-color: inherit;
}

/****************** BOOK TUTOR ***************/
.book-tutor-section {
  position: relative;
  margin: 55px auto 5rem;
  width: min(778px, 95%);
  min-height: 812px;
}
.book-tutor-block {
  border: 1px solid #ECECEC;
  border-top: 5px solid var(--primary);
  background-color: var(--white);
  position: relative;
  width: 100%;
  min-height: 812px;
  height: 100%;
}
.btn-group-lg {
  width: 100%;
}

.btn-group-lg > .btn {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #CBCBCB;
  border-radius: 0;
  padding: 1.4rem 2rem;
}
.btn-group-lg > .btn.active {
  background-color: #ECFBF9;
  color:  #676767;
}

.padding-form {
  padding: 60px;
}
.text-deep {
  color: #4A5253;
}

@media only screen and (max-width: 768px) {
  .padding-form {
    padding: 35px 20px;
  }
  .btn-group-lg > .btn {
    padding: .75rem 1rem;
  }
}

.tab-group {
  margin: 0 auto;
  width: min(380px, 95%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab-group > .tab {
  position: relative;
  width: 125px;
  height: 125px;
  background: var(--bg);
  border: 2px solid rgba(134, 133, 133, 0.15);
}
.tab-group > .tab.active {
  border: 2px solid var(--primary);
}
.tab-group > .tab.active::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  background: var(--bg);
  border-right: 2px solid var(--primary);
  border-bottom: 2px solid var(--primary);
  bottom: -20%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}
.tab-text {
  font-family: 'Muli';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  color: #1D1E1E;
}

@media only screen and (max-width: 576px) {
  .tab-group {
    width: max-content;
  }
  .tab-group > .tab {
    width: 98px;
    height: 98px;
  }
  .tab-group > .tab.active::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    background: var(--bg);
    border-right: 2px solid var(--primary);
    border-bottom: 2px solid var(--primary);
    bottom: -20%;
    left: 50%;
  }
}


/*********** React Calender Ovveride *************/
.react-calendar {
  background: #F5F5F5;
  border-radius: 16px;
  border: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, .2);
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
.react-calendar button {
  font-weight: 400 !important;
}
.react-calendar button:enabled:hover {
  border-radius: 6px;
}
.react-calendar__tile--active {
  background: var(--primary);
  color:var(--white);
  border-radius: 6px;
}
.react-calendar__month-view__weekdays {
  font-size: .72rem;
  font-weight: 500;
}
.react-calendar__month-view__days button, .react-calendar__navigation button{
  font-size: .85rem;
}
.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  background-color: var(--primary);
}

.cal-sidebar {
  background: var(--white);
  border-radius: 0;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.02);
  width: 100%;
}
.cal-sidebar .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  color: var(--black);
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
}
/*********** End React Calender Overide *************/

/*********** Dashboard start *************/
.book-tutor-cta {
  background: linear-gradient(120.67deg, #5CE0EB 7.68%, #1EC0CD 100%), url("data:image/svg+xml,%3Csvg width='884' height='93' viewBox='0 0 884 93' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M160.763 67.1406C94.9277 65.8225 26.4564 78.1252 0.450195 84.4413V92.0619H238.247H883.772V8.64777C875.935 2.74357 858.336 -5.52232 850.641 8.64777C841.022 26.3604 789.722 41.3955 685.519 34.5988C581.316 27.8021 537.497 34.5988 472.303 52.7234C407.11 70.8479 243.056 68.7883 160.763 67.1406Z' fill='white' fill-opacity='0.09'/%3E%3C/svg%3E%0A");
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  color: var(--white);
  padding: 1rem 40px;
  margin-bottom: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upcoming-card {
  background: var(--off-bg);
  border-radius: 10px;
  border-color: var(--white);
  border: 1px solid #D2F1F1;
}
.upcoming-card .card-header {
  border-radius: 10px 10px 0px 0px;
  background-color: var(--white);
  border-color: var(--white);
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.05);
}
.upcoming-card .card-body {
  padding: 2rem 1.4rem;
}

.dot-text {
  padding: 0 15px;
}
.dot-text:before {
  content: " ";
  position: absolute;
  top: 9px;
  left: 0;
  height: 8px;
  width: 8px;
  border-radius: 50%;
}
.dot-text.--primary:before {
  background-color: var(--primary);
}
.dot-text.--danger:before {
  background-color: #dc3545;
}
.session-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #303030;
}
.txt-14--light {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.171429px;
  color: #4D4D4D;
}
.dashboard-avatar {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}
.tab {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #9297A9;
  position: relative;
  cursor: pointer;
}
.tab.active{
  font-weight: bold;
  color: #737272;
}
.tab.active:after {
  content: " ";
  position: absolute;
  bottom: -4px;
  left: 0;
  height: 3px;
  background-color: var(--primary);
  width: 50%;
}
.txt-12-light {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #9297A9;
}
.line-height--reset {
  line-height: 0;
}
.tick {
  width: 8%;
}
.dotted-divider {
  border-bottom: 2px dashed var(--off-grey);
  margin-bottom: 1rem;
}
.card-footer.bg-dashboard-card {
  border-top: none;
  margin-top: -2rem;
  padding: .75rem 1.25rem;
}
.btn-outline-primary {
  border-color: var(--primary);
  color: var(--primary);
}
.btn-outline-primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
}

.tutor-credits {
  margin-bottom: 30px;
}

.tutor-credits > .title {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.tutor-credits > .student-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.tutor-credits > .student-container > .student {
  width: 150px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  min-height: 140px;
}

.tutor-credits > .student-container > .student > .name {
  margin-bottom: 5px;
}

.tutor-credits > .student-container > .student > .credit-left {
  font-size: 2rem;
  font-weight: 700;
  color: #7A7A7A;
  font-family: 'Montserrat';
  margin-bottom: 5px;
}

.tutor-credits > .student-container > .student > a > .button {
  font-size: 0.9rem;
  padding: 3px;
  width: 100px;
  color: #FFFFFF;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  background-color: #5EE1E8;
}

.tutor-credits > .student-container > .student  > .button {
  font-size: 0.9rem;
  padding: 3px;
  width: 100px;
  color: #FFFFFF;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  background-color: #5EE1E8;
}

.tutor-credits > .student-container > .student > a > .subscribe {
  background-color: #5EE1E8;
}

.tutor-credits > .student-container > .student > .silver {
  background-color: #AFAFAF;
}

.tutor-credits > .student-container > .student > .charcol {
  background-color: #2A2A2A;
}

.tutor-credits > .student-container > .student > .ivory {
  background-color: #FEF6DE;
  color: #000000;
}

.tutor-credits > .student-container > .student > .turquoise {
  background-color: #5CE0EB;
}

.tutor-credits > .student-container > .student > .teal {
  background-color: #009BA7;
}

@media only screen and (max-width: 768px) {
  .upcoming-sessions {
    flex-wrap: wrap;
  }
  .upcoming-sessions > div {
    margin-bottom: 1rem;
  }
  .tick {
    width: auto;
  }
}



/*********** Dashboard end *************/

/********* Tutor Dashboard **************/
.tutor-dashboard-grid {
  display: grid;
  grid-template-columns: 1fr 354px;
  gap: 2rem;
}
.stat-card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}
.stat-card {
  border-radius: 5px;
  width: 100%;
  min-height: 123px;
  color: var(--white);
  background-color: var(--primary);
  padding: 1rem 2.2rem .8rem 1.75rem;
  display: grid;
  grid-template-rows: 35px 1fr;
  gap: 1rem;
}
.stat-card.teal {
  background-color: var(--primary-darker);
}
.stat-card.grey {
  background-color: #AFAFAF;
}
.stat-card h2,h6 {
  color: inherit;
}
.stat-card h6 {
  font-weight: bold;
font-size: 14px;
line-height: 20px;
}
.stat-card h2 {
  font-weight: bold;
font-size: 40px;
line-height: 50px;
margin-bottom: 0;
}

.upcoming-completed-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.2rem;
  margin-bottom: 2rem;
}
.upcoming-row {
  display: grid;
  grid-template-columns: 20px minmax(210px, 1fr) minmax(92px, min-content) 10px;
  gap: .8rem;
  justify-items: flex-start;
  align-items: center;
  margin-bottom: 2rem;
}
.image-group {
  display: flex;
  width: 100%;
}
.image-group img {
  display: flex;
  width: 46px;
  height: 46px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid var(--white);
  margin-left: -1rem;
}
.image-group img:first-child {
  margin-left: 0;
}
.badge-grid {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.right-section {
  display: grid;
  grid-template-rows: 335px 1fr;
  gap: 20px;
}
/* NOTIFICATION TIMELINE */
.timeline {
  width: 100%;
  display: block;
  float: left;
  margin-bottom: 2rem;
}
.timeline .timeline-event {
  width: 100%;
  float: left;
  position: relative;
  font-size: 16px;
}

.timeline .timeline-event.active:before {
  background-color: var(--primary-darker);
  border: 0;
}
.timeline .timeline-event:before {
  content: "";
  width: 14px;
  height: 14px;
  background-color: var(--white);
  border: 2px solid var(--primary-darker);
  
  border-radius: 50%;
  z-index: 1;
  position: absolute;
  top: 13%;
  margin-top: -8px;
  left: 12px;
  margin-left: -8px;
  box-sizing: border-box;
}
.timeline .timeline-event:after {
  content: "";
  background-color: var(--primary-darker);
  width: 1.5px;
  height: 100%;
  position: absolute;
  z-index: 0;
  margin-left: 0px;
  top: 13%;
  bottom: -50%;
  left: 10px;
}
.timeline .timeline-event:last-of-type:after {
  height: 0;
}
.timeline .timeline-event__image {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: auto;
  position: relative;
  z-index: 1;
}
.timeline .timeline-event__date {
  display: inline-block;
  width: 90%;
  vertical-align: middle;
  color: var(--black);
  padding-left: 1rem;
  -webkit-transition: padding 300ms;
  transition: padding 300ms;
}
.timeline-time {
  color:  #2A2A2A;
  font-weight: bold;
  font-size: 12px;
  line-height: 27px;
}
.timeline-data {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #7A7A7A;
  margin-bottom: 1.6rem;
}

@media screen and (max-width: 576px) {
  .tutor-dashboard-grid {
    display: block;
    gap: 2rem;
  }
  .upcoming-completed-grid {
    display: block;
  }
  .upcoming-card {
    margin-bottom: 1.5rem;
  }
  .upcoming-card .card-body {
    padding: 1.6rem .75rem;
  }
  .upcoming-row {
    gap: 10px;
    grid-template-columns: 1rem minmax(195px, 1fr);
    grid-template-rows: 1fr 1fr;
  }
  .image-group {
    grid-row: 2;
    grid-column: 2 / -1;
  }
  .right-section {
    display: block;
  }
  .cal-sidebar {
    margin-bottom: 2rem;
  }
  .book-tutor-block {
    min-height: 600px;
  }
}


/********** Tutor Dashboard End *********/

/********** Book Tutor Builder **********/

.tutor-list__header {
  background-color: #E9EFEF;
  height: 102px;
  display: grid;
  grid-template-columns: 110px 1fr max-content;
  padding: 1rem 1.5rem 1rem 2rem;
}

.step-nav ul {
  margin: 0px; 
  list-style-type: none; 
  padding-left: 0px;
  display: flex;
  justify-content: center;
}
.step-nav ul li {
  padding: 0;
  margin-right: 6rem;
  position: relative; 
  display: inline-block;
  color: #C5C7C7;
}
.step-nav ul li:last-child {margin-right: 0px;}

.step-nav ul li:last-child:after {width: 0;}
.step-nav ul li span {
  display: flex;
  justify-content: center;
  align-items: center; 
  border: 1px solid #C5C7C7;
  background-color: #C5C7C7; 
  opacity: .88;
  border-radius: 50%; 
  margin: 0 auto;
  width: 38px; 
  height: 38px;
  color: #fff; 
  font-weight: 700;
}
.step-nav ul li.active {
  color: var(--black);
} 
.step-nav ul li.active span {
  background: #5EE1E8;
  border: 1px solid #5EE1E8;
  color: #FFF;
}

@media only screen and (max-width: 768px) {
  .tutor-list__header {
    height: auto;
    grid-template-columns: repeat(2, 1fr);
    padding: 1.5rem;
    gap: 1.5rem .5rem;
  }
  .step-nav {
    grid-row: 2;
    grid-column: 1 / -1;
  }
  .step-nav ul li {
    margin-right: 1rem;
    text-align: center;
  }
}
/********** End Book Tutor Builder **********/
@media only screen and (max-width: 768px) {
  .hide-on--mobile {
    display: none;
  }
  .hide-on--desktop {
    display: block;
  }
}
@media only screen and (min-width: 769px) {
  .hide-on--mobile {
    display: block;
  }
  .hide-on--desktop {
    display: none;
  }
}

/* Material Type Toggle Switch with checkbox */

.toggle-switch input[type=checkbox]{
  position: absolute;
  opacity: 0;
	height: 0;
	width: 0;
	visibility: hidden;
}

.toggle-switch label {
  cursor: pointer;
  text-indent: -9999px;
  top: 5px;
  width: 38px;
  height: 14px;
  background: #73c0c6;
  filter: lighten;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  position: relative;
  margin-bottom: 0;
}
.toggle-switch label::after {
  content: '';
  position: absolute;
  top: -3.5px;
  left: 1px;
  width: 20px;
  height: 20px;
  background: var(--primary-darker);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  transition: 0.3s;
}
  
.toggle-switch input:checked + label {
    background: #73c0c6;
}
.toggle-switch input:checked + label::after {
  left: calc(100% - 1px);
  transform: translateX(-100%);
}
.toggle-label {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--off-green);
}

/* ***************** Profile Student/ parent  *********** */
.profile-container {
  border-radius: 15px;
  background-color: var(--white);
}

.profile-header {
  border-bottom: 1px solid #D1D0D0;
  padding: 0 .85rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 30px;
}
.profile-tab {
  outline: none;
  border:0;
  background-color: var(--white);
  color: var(--grey);
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 20px;
  padding: 1rem 0;
}
.profile-tab:hover {
  font-weight: 600 !important;
}
.profile-tab:active, .profile-tab:focus {
  outline: none;
}
.profile-tab.active {
  font-weight: 600 !important;
  border-bottom: 3px solid var(--primary);
}

.profile-body {
  padding: 50px 80px;
}
.profile-avatar {
  height: 110px;
  width: 110px;
  position: relative;
  margin-bottom: 20px;
  z-index: 1;
  cursor: pointer;
}
.profile-img {
  height: 110px;
  width: 110px;
  padding: 5px;
  object-fit: contain;
  border-radius: 50%;
  margin: 0 auto;
  border: 3px solid var(--primary);
}
.upload-photo-label {
  margin: auto;
  cursor: pointer;
}
.upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  height: 0;
}
.profile-avatar:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  font-size: 22px;
  pointer-events: none;
  font-weight: 700;
  letter-spacing: 2px;
  color: #fff;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  background: url("data:image/svg+xml,%3Csvg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M20.0035 15.0569C21.6633 16.7166 21.6633 19.4077 20.0035 21.0674C18.3437 22.7272 15.6527 22.7272 13.9929 21.0674C12.3331 19.4077 12.3331 16.7166 13.9929 15.0569C15.6527 13.3971 18.3437 13.3971 20.0035 15.0569Z' fill='white'/%3E%3Cpath d='M29.7499 6.37466H26.8134L24.6873 2.12451H9.31066L7.1866 6.37675L4.25425 6.38197C1.9165 6.38614 0.0134342 8.29122 0.0114156 10.63L0 27.6243C0 29.9683 1.90612 31.8755 4.25015 31.8755H29.7499C32.0939 31.8755 34.0001 29.9694 34.0001 27.6253V10.6247C34 8.28078 32.0939 6.37466 29.7499 6.37466ZM16.9995 26.5628C12.3125 26.5628 8.49918 22.7495 8.49918 18.0625C8.49918 13.3755 12.3125 9.56218 16.9995 9.56218C21.6865 9.56218 25.4998 13.3755 25.4998 18.0625C25.4998 22.7495 21.6865 26.5628 16.9995 26.5628Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='34' height='34' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E"), linear-gradient(0deg, rgba(51, 51, 51, 0.5), rgba(51, 51, 51, 0.5));
  background-repeat: no-repeat;
  transform: scale(.5,.5);
  transform-origin: center;
  background-position: center center;
  transition: all 0.3s ease-out;
}
.profile-avatar:hover:after{
  transform: scale(.9,.9);
  opacity: 1;
  visibility: visible;

}

.flexible-width {
  width: min(640px, 100%);
}
.profile-tab > hr {
  border-bottom: 1px solid #D1D0D0;
  margin: 1.5rem -80px;
}
.profile-separate {
  border-bottom:  1.5px solid #E9F3FE;
  margin: 1.5rem -100% 1.5rem 0;
}
.subscription-grid {
  display: grid;
  margin: 4rem 0;
  grid-template-columns: 490px 226px;
  gap: 50px 120px;
}
@media only screen and (max-width: 768px) {
  .profile-cta {
    flex-direction: column;
    width: 100%;
    gap: 0.8rem;
  }
  .profile-header {
    gap: 10px;
  }
  .profile-body {
    padding: 50px 1rem;
  }
  .profile-tab hr {
    border-bottom: 1px solid #D1D0D0;
    margin: 0 -1rem 1rem;
  }
  .subscription-grid {
    display: grid;
    margin: 4rem 0;
    grid-template-columns: 1fr;
    gap: 50px;
  }
}
/*********** Profile End *************/

/*********** Tutor Profile Start *************/

.tutor-profile-grid {
  padding: 12px 22px;
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 30px;
}
.tutor-profile-header {
  min-height: 25rem;
  padding-top: 4rem;
}

.tutor-profile-tab {
  outline: none;
  border: 1px solid var(--bg);
  background-color: var(--bg);
  color: #7A7A7A;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.15px;
  width: 100%;
  height: 44px;
  text-align: center;
  display: grid;
  place-content: center;
  margin-bottom: 1rem;
  border-radius: 3px;
}
.tutor-profile-tab:hover {
  font-weight: 600 !important;
  border: 1px solid var(--primary-darker);
}
.tutor-profile-tab:active, .tutor-profile-tab:focus {
  outline: none;
}
.tutor-profile-tab.active {
  font-weight: 600 !important;
  color: #2A2A2A;
  border: 1px solid var(--primary-darker);
}
.tutor-profile-separate {
  border-bottom: 1px solid #E9EFEF;
  margin: 1.5rem -20vw 1.5rem 0;
}

.availability-grid {
  overflow-x: auto;
  width: auto;
  max-width: 100%;
  display: grid;
  grid-template-areas: 
    "t d d d d d d"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c"
    "t c c c c c c";

}
.day-row {
  grid-area: d;
  display: grid;
  grid-template-columns: repeat(7, 60px);
  gap: 0 45px;
  padding: 1rem;
}
.day, .time {
  font-weight: 700;
}
.time-col {
  grid-area: t;
  display: grid;
  padding: 1rem;
  width: max-content;
  gap: 30px;
  /* grid-template-rows: repeat(25, 120px); */
}
.checkbox-grid {
  grid-area: c;
  display: grid;
  grid-template-columns: repeat(7, 60px);
  justify-items: center;
  gap: 20px 45px;
}
.checkbox-grid-hobbies {
  grid-area: c;
  display: grid;
  grid-template-columns: repeat(3, 33%);
  justify-items: start;
  gap: 20px 45px;
}
.ingrid-position {
  grid-area: c;
  grid-row: 27;
  grid-column: 6 / 7;
}

@media only screen and (max-width: 768px) {
  .tutor-profile-grid {
    padding: 12px 1rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
  }
  .tutor-profile-header {
    min-height: auto;
    padding-top: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .availability-wrapper {
    overflow: auto;
    max-width: 85vw;
    height: 600px;
  }
  .ingrid-position {
    grid-column: 7 / span 1;
  }
}

/*********** Tutor Profile End *************/

/*********** React paginate *************/

.react-paginate {
  display: inline-flex;
  list-style: none;
  margin-top: 1.5rem;
}
.react-paginate .page,
.react-paginate .break,
.react-paginate .link {
  padding: 0.6rem 1rem;
  cursor: pointer;
}
.react-paginate .page:hover {
  background-color: var(--light-primary);
}
.react-paginate .active {
  background-color: var(--primary);
  color: var(--white);
}

/****************** Media ******************/
.var-row{ align-items: center; }
.main-body-outer{display: flex; width: 100%;}
.main-body-wrap{ display: flex;}
.main-body-sec{ width: 100%; background-color: #fff; border: #ddd solid 1px; padding: 30px; position: relative;}
.main-body-sec .back-page{ position: absolute; top: 30px; right: 30px; }
.main-body-sec .back-page a{ text-transform: uppercase; font-size: 13px; font-weight: 700; color: #5CE0EB;} 

.main-body-sec.m-body-left{ width: 63%; }
.main-body-sec.m-body-right{ width: 35%; }

#tabs nav{
	display: inline-block; width: auto;
}
#tabs h6.section-title{
    color: #333;
}

#tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #333;
    background-color: transparent;
    border-bottom: #5CE0EB 2px solid !important;
    font-size: 20px;
    font-weight: bold;
}
#tabs .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    color: #333;
    font-size: 15px;
}
.dash-tab .tab-pane h2{ color: #333; font-size: 20px; font-weight: 800;  }
.podcasts-wrap .podcasts-box{ padding: 15px 0px; border-bottom: #ddd solid 1px; }
.dash-tab .podcasts-wrap .podcasts-box .podcast-date{ color: #666; font-size: 12px; padding-bottom: 10px; }
.dash-tab .podcasts-wrap .podcasts-box .podcasts-box-info{ display: flex; justify-content: space-between;}
.podcasts-img{ width: 100px; }
.podcasts-img img{ width: 75px; }
.podcasts-info{ width: 100%; padding-left: 15px; padding-right: 15px;}
.podcasts-info-top{ display: flex; justify-content: space-between; align-items: center;}
.podcasts-info-top h3{ color: #333; font-size: 17px; font-weight: 700; line-height: 0;}
.podcasts-info-top h3 a{ color: #333; }
.podcasts-info-top .podcast-time{ color: #666; font-size: 13px; }
.podcasts-info p{ font-size: 14px; color: #666; line-height: 20px; font-weight: 600; padding-top: 10px; }
.podcasts-media{ width: 100px; text-align: center; }
.podcasts-action a{ display: inline-block; }

.media-modal .modal-body iframe{ width: 100%; }

.m-body-right h2{ font-size: 17px; font-weight: 700; color: #333; position: relative; }
.m-body-right h2:after{ content: ''; width: 70px; height: 2px; background-color: #5CE0EB; position: absolute; bottom: -7px; left: 0; }
.m-body-right .podcasts-wrap{ margin-top: 30px; }
.m-body-right .podcasts-box .podcast-date{ text-align: right; color: #666; font-size: 13px; padding-bottom: 15px; }
.m-body-right .podcasts-box .podcasts-info{ padding: 0px; }
.podcasts-box audio {
  width: 100%;
  filter: sepia(35%) saturate(100%) grayscale(1) contrast(99%) invert(0%);
  margin-top: 0.5rem;
  background-color: white;
}
.podcasts-action-wrap{ display: flex; }
.podcasts-box.other-box .podcasts-box-info{ align-items: center; }
.podcasts-box.other-box .podcasts-img{ width: auto; font-weight: 600; color: #333; min-width: 120px;  }
.podcasts-box.other-box .podcasts-action{ width: 100px; text-align: center; }

.dash-tab .podcasts-wrap .podcasts-box.other-box .podcasts-box-info{ justify-content: inherit; }
.podcasts-box.other-box .podcasts-info{ width: auto; }
.podcasts-box.schedule-box .podcasts-img{ width: 70px; }
.podcasts-box.schedule-box .podcasts-img img{ width: 50px; height: 50px; border-radius: 100%; }
.podcasts-box.schedule-box .schedule-title{ color: #333; font-size: 16px; font-weight: 700; padding-bottom: 10px;}
.podcasts-box.schedule-box .schedule-title a{ color: #333; }
.podcasts-box.schedule-box .schedule-info-top .schedule-auth{ color: #2A2A2A; font-size: 14px; }
.schedule-date-time{ padding-top: 5px; }
.schedule-date-time span{ color: #2a2a2a; font-size: 13px; margin-right: 15px; display: inline-block; }
.podcasts-box.schedule-box .podcasts-action-wrap{ border-left: #ddd solid 1px; }
.podcasts-box.schedule-box .podcasts-action-wrap .schedule-action{ text-align: center; padding: 0px 15px; width: 100px;}
.podcasts-box.schedule-box .podcasts-action-wrap .schedule-action span{ display: block; font-size: 13px; }
.podcasts-box.schedule-box .podcasts-action-wrap .schedule-action.accept{ color: #009BA7; }
.podcasts-box.schedule-box .podcasts-action-wrap .schedule-action.decline{ color: #EB5757; }
.podcasts-box.schedule-box .podcasts-action-wrap .schedule-action.propose{ color: #3D5154; }
.podcasts-box.schedule-box .podcasts-action-wrap .schedule-action.tentative{ color: #009BA7; }
.podcasts-box.schedule-box .podcasts-action-wrap .schedule-action i{ font-size: 16px; }
.dash-tab .podcasts-wrap .podcasts-box.schedule-box .podcasts-box-info{ justify-content: inherit; }
.podcasts-box.schedule-box .podcasts-info{ width: auto; }

.dashboard-body{ background-color: #fff; height: 100%; font-family: 'Montserrat', sans-serif;}
.dashboard-wrap .top-panel{ background: #5ce0e8; padding: 30PX 0PX; text-align: center; color: #fff; }
.dashboard-wrap .top-panel h3{ text-transform: uppercase; font-size: 16px; font-weight: 700; padding-bottom: 5px;}
.dashboard-wrap .top-panel h2{ font-size: 30px; font-weight: 700; }
.priceing-wrap{ padding: 30px 0px; }
.priceing-box{ border: #f6f6f6 solid 1px; border-radius: 5px; margin-top: 30px;}
.priceing-box .priceing-box-head{ text-align: center; padding: 10px 10px; background-color: #d2f1f1; color: #000; font-weight: 700; border-radius: 5px;}

.priceing-box .priceing-box-head.black-head{ background-color: #000; color: #fff; }
.priceing-box .priceing-box-head.yellow-head{ background-color: #fef6de; color: #7a7a7a; }
.priceing-box .priceing-box-head.seagreen-head{ background-color: #5CE0EB; color: #fff; }
.priceing-box .priceing-box-head.blue-head{ background-color: #009BA7; color: #fff; }
.priceing-box .priceing-box-head.light-gray-head{ background-color: #afafaf; color: #fff; }
.priceing-box .priceing-box-head.deep-gray-head{ background-color: #7a7a7a; color: #fff; }



.priceing-box .priceing-box-body{ background-color: #fff; padding: 15px 10px; text-align: center;}
.priceing-box .priceing-box-body h2{ font-size: 20px; font-weight: 700; color: #009BA7; padding-bottom: 15px; }
.priceing-box .priceing-box-body h3{ font-size: 14px; font-weight: 400; color: #2a2a2a; padding-bottom: 5px; }
.priceing-box .priceing-box-body h4{ font-size: 18px; font-weight: 600; color: #2a2a2a; padding: 15px 0px 0px 0px; }
.priceing-box .priceing-box-body p{ font-size: 14px; line-height: 25px; font-weight: 400; color: #2a2a2a; padding-bottom: 5px; }
.priceing-box .priceing-box-body p.des{ padding-top: 30px; }
.divider{ margin: 15px 0px; border-top: #ddd solid 1px; }
.priceing-box .priceing-box-body h5{ font-size: 16px; font-weight: 700; color: #6E7A81, 100%; padding: 0px 0px 15px 0px; }
.priceing-box .priceing-box-body ul{ text-align: left; list-style: none;}
.priceing-box .priceing-box-body ul li{ position: relative; background-image: url(/images/tick-bullet.png); background-repeat: no-repeat; background-position: top 3px left; background-size: 15px; padding-left: 25px; margin-top: 10px;  }
.price-btn{ margin-top: 30px;}
.price-btn a{ display: inline-block; padding:10px 50px; background-color: #5ce0e8; color: #fff; border-radius: 5px; font-weight: 600; letter-spacing: 1px;}
.priceing-box .priceing-box-body .tag{ margin-bottom: 10px; }
.priceing-box .priceing-box-body .tag span{ display: inline-block; padding: 10px 15px; line-height: 14px; border-radius: 3px; color: #fff; background-color: #5ce0eb; }

.tutoring-wrap{ background-color: #f8f5f5; }
.tutoring-list .tutoring-date{ color: #2a2a2a; font-weight: 700; padding-bottom: 15px; }
.tutoring-list-box{ background-color: #fff; border: #E9EFEF solid 1px; border-left: #009BA7 solid 3px; margin-bottom: 15px; padding:10px; border-radius: 5px; display: flex; align-items: center; justify-content: space-between; }
.tutoring-list-box .tutoring-name{ font-size: 14px; color: #3D5154; }
.tutoring-list-box .tutoring-pic img{ height: 40px; width: 40px; border-radius: 100%; }
.tutoring-list-box .t-online{ font-size: 14px; color: #3D5154; position: relative; }
.tutoring-list-box .t-online span{ height: 10px; width: 10px; border-radius: 100%; display: inline-block; margin-right: 5px; background-color: #ccc;}
.tutoring-list-box .t-online span.active{ background-color: #5ce0eb; }
.tutoring-list-box .date-time{ font-size: 14px; color: #3D5154; }
.tutoring-list-box .date-time span{ display: inline-block; margin-right: 10px; }
.tutoring-list-box .date-time span i{ font-size: 13px; }
.tutoring-list-box .t-bnt a{ display: inline-block; padding: 7px 15px; font-size: 13px; margin-left: 5px; line-height: 15px; border-radius: 5px; border: #5ce0eb solid 2px; color: #5ce0eb; }
.tutoring-list-box .t-bnt a:hover{ background-color: #009BA7; color: #fff; }
.tutoring-list-box .t-bnt a.progres{ background-color: #AFAFAF; color: #fff; border: #AFAFAF solid 1px;}
.tutoring-list-box .t-bnt a.done{ background-color: transparent; color: #009BA7; border: none; }

.tutoring-list-box:hover{ background-color: #E8F3F3; border: #E9EFEF solid 1px; border-left: #009BA7 solid 3px;}

@media only screen and (min-width: 768px) and (max-width: 1023px) {

	.main-body-sec{ padding: 15px; margin-left: 0px; }
	.main-body-outer{ display: block; padding: 0px 15px; }
	.main-body-sec.m-body-left{ width: 100%; }
	.main-body-sec.m-body-right{ width: 100%; margin-top: 15px; }

}

@media only screen and (max-width: 767px) {

	.sidebar{ display: none; }

	.main-body-sec{ padding: 15px; margin-left: 0px; }
	.main-body-outer{ display: block; padding: 0px 15px; }
	.main-body-sec.m-body-left{ width: 100%; }
	.main-body-sec.m-body-right{ width: 100%; margin-top: 15px; }
	.main-body-sec .back-page{position: relative; top: 0; right: 0; text-align: right;}
	.dash-tab .tab-content{ padding: 20px 0px !important; }
	.dash-tab .podcasts-wrap .podcasts-box{ position: relative; }
	.dash-tab .podcasts-wrap .podcasts-box .podcasts-box-info{ display: block; text-align: center; }
	.podcasts-img{ width: 100%; margin-bottom: 15px }
	.podcasts-info{ padding: 0px; margin-bottom: 0.5rem; }
	.podcasts-info-top{ display: block; }
	.podcasts-info-top .podcast-time{ position: absolute; top: 15px; right: 0; }
	.podcasts-action-wrap{ display: flex; justify-content: center; }
	.podcasts-media{ width: auto; }
	.podcasts-action-wrap .podcasts-media{ margin: 0px 5px; }
  .podcasts-action-wrap .podcasts-action{ margin: 0px 5px; }
  
  .podcasts-box.schedule-box .podcasts-img{ width: 100%; text-align: center; }
	.podcasts-box.schedule-box .podcasts-action-wrap{ border: none; }
	.podcasts-box.schedule-box .podcasts-action-wrap .schedule-action span{ font-size: 11px; }
	.podcasts-box.schedule-box .podcasts-action-wrap .schedule-action{ padding: 0px 10px; width: auto; margin-top: 15px; }

}


.whiteboard-container {
    height: calc(100vh - 150px);
    overflow-y: scroll;
    border: 3px solid var(--light-primary);
    border-radius: 15px;
}
.appliences-containor {
  flex: 0.5 0 auto;
}
.appliences-containor > * {
  cursor: pointer;
}

.room-btn-container {
  padding: 0rem 2rem;
  background: var(--off-grey);
  margin-bottom: 1rem;
  border-radius: 15px;
}

.agora-player__box {
  width: 360px !important;
  height: 270px !important;
}

.confirmation-page {
  margin-top: 50px;
}

.profile-info-alert {
  background-color: #EBFBFB;
  border-color: #EBFBFB;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.layout-container.profile-form {
  margin-top: 110px;
}

.subscription-table > .row.header {
  padding-bottom: 40px;
}

.subscription-table > .row {
  margin-bottom: 20px;
}

.subscription-table > .row p {
  color: #7A7A7A;
}

.subscription-table > .row > .col.student > .name {
  font-size: 1.18rem;
  font-weight: bold;
}

.subscription-table > .row > .col.plan-status > .status,
.subscription-table > .row > .col.plan-price > .price {
  font-size: 1.1rem;
  font-weight: bold;
}

.subscription-table > .row > .col.plan-status > .status.active {
  color: #009BA7;
}

.subscription-table > .row > .col.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subscription-table > .row > .col.actions > .button {
  width: 120px;
  border-radius: 5px;
  color: #FFFFFF;
  box-shadow: none;
  outline: none;
  font-size: 1rem;
  font-weight: normal;
  padding: 5px;
}

.subscription-table > .row > .col.actions > a > .button {
  width: 120px;
  border-radius: 5px;
  color: #FFFFFF;
  box-shadow: none;
  outline: none;
  font-size: 1rem;
  font-weight: normal;
  padding: 5px;
}

.subscription-table > .row > .col.actions > a > .button.upgrade {
  background-color: #009BA7;
  border: 1px solid #009BA7;
}

.subscription-table > .row > .col.actions > .button.cancel {
  background-color: #EB5757;
  border: 1px solid #EB5757;

}
