.schedule-section {
  background: #FFFFFF;
  box-shadow: 0px 2px 20px rgba(90, 91, 92, 0.15);
  border-radius: 10px;
  min-height: 812px;
  padding: 70px 56px 90px;
}

.py-6 {
  padding-top: 120px;
  padding-bottom: 280px;
}

.select-date__wrapper {
  padding: 70px 106px 120px 180px;
}
@media only screen and (max-width: 768px) {
  .select-date__wrapper {
    padding: 30px 10px;
  }
  .schedule-section {
    padding: 30px 10px;
  }
  .dropdown-add__menu {
    transform: translate3d(-68px, 44px, 0px) !important;
  }
}
.cal-schedule {
  background: #FFFFFF;
  width: 100%;
  border: 1px solid #AFAFAF;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: none;
}

.cal-schedule .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  color: var(--black);
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

.cal-schedule .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  background-color: var(--off-grey);
  outline: none;
}
.cal-schedule button:enabled:hover {
  border-radius: 2px;
}
.cal-schedule .react-calendar__tile--active {
  background-color: var(--primary-darker) !important;
  border-radius: 2px !important;
}

.add-btn-dropdown {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  background-color: var(--primary-darker);
  color: white;
  padding: 0;
  display: grid;
  place-content: center;
}
.add-btn-dropdown.dropdown-toggle::after {
  content: "";
  all: unset;
}
.add-btn-dropdown svg{
  line-height: 1;
  font-size: 24px;
}
.dropdown-add__menu {
  width: 287px;
  background: #FFFFFF;
  box-shadow: 0px 2px 20px rgba(49, 48, 48, 0.2);
  padding: .8rem 1rem;
  border: none;
}
.people-add {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1.4rem;
}
.people-add li {
  display: grid;
  grid-template-columns: 1fr 40px;
  gap: 10px;
  align-items: center;
  padding: 4px 10px;
  border-bottom: 0.5px solid #D2D0D0;
}
.people-add li:last-child {
  border-bottom: 0;
}