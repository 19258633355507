.menu-bar__filter {
  min-height: 69px;
  padding: 1rem 54px;
  display: grid;
  grid-template-columns: .55fr 1fr;
  background: #FFFFFF;
  border-bottom: 0.5px solid #AFAFAF;
}
.custom-btn-dropdown {
  background: #FFFFFF;
  border: 1px solid #E0DEDE;
  box-sizing: border-box;
  border-radius: 2px;
}
.custom-btn-dropdown:focus, .custom-btn-dropdown:hover {
  border: 1px solid var(--primary-darker);
}
.dropdown-toggle::after {
  margin-left: 1rem;
  vertical-align: middle;
}

.dropdown-filter {
  width: min(670px, 100vw);
  margin: 0 auto;
  background: #FFFFFF;
  padding: 24px 34px;
}
.filter-dropdown__inner-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 65px;
}

.checkbox-2::before {
  width: 20px;
  height: 20px;
  border: 1px solid #AFAFAF;
  border-radius: 2px !important;
}

.custom-control-input:checked ~ .custom-control-label.checkbox-2::before {
  color: var(--primary-darker);
  border-color: #AFAFAF;
  background-color: #FFF;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label.checkbox-2::after {
  background-image: none;
  width: 6px;
  height: 11px;
  border: solid var(--primary-darker);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

@media only screen and (max-width: 768px) {
  .menu-bar__filter {
    grid-template-columns: 1fr;
    padding: 1.5rem;
    gap: 1rem;
  }
  .dropdown-filter {
    padding: 24px;
  }
  .filter-dropdown__inner-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

.modal-container-student {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 60px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 40%;
}

.modal-container-student > select {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
}