.request-alert__wrapper {
  background: var(--white);
  box-shadow: 0px 11px 36.4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: min(1220px, 95%);
  min-height: 80px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: all .3s ease-out;
  visibility: hidden;
  opacity: 0;
}
.request-alert__wrapper.show {
  top: 120px;
  opacity: 1;
  visibility: visible;
}

.request-alert__close {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 0;
  background-color: var(--white);
  border: none;
}
.request-alert__close:focus {
  outline: 2px auto var(--primary-darker);
}

.request-alert__grid {
  padding: .9rem 2.2rem;
  display: grid;
  grid-template-columns: 50px 2fr 1fr 1.2fr;
  gap: 28px;
  align-items: center;
}

@media only screen and (max-width: 576px) {
  .request-alert__grid {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr;

  }
  .profile-wrapper {
    display: grid;
    place-content: center;
  }
}