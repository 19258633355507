.capitalize {
  text-transform: capitalize;
}
.lowercase {
  text-transform: lowercase;
}
.uppercase {
  text-transform: uppercase;
}
.bold {
  font-weight: 900;
}
.semibold {
  font-weight: 600;
}
.italic {
  font-style: italic;
}
.normal {
  font-style: normal;
}
.big-tag {
  border-radius: 4px;
  padding: 9px 28px;
  font-family: inherit;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.4px;
  text-align: center;
}
.tag {
  padding: 3px 12px;
  border-radius: 2px;
  font-family: inherit;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.4px;
  text-align: center;
  color: #000;
  display: inline-block;
}

.tag-clear {
  background: transparent;
  color: #4A4A4A;
}
.tag-teal {
  background: var(--primary-darker);
  color: var(--white);
}
.tag-pin {
  padding: 4px;
  color: #2A2A2A;
  background: transparent;
}