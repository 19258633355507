.tutor-detail-container > .tutor-detail {
    background-color: #FFFFFF;
    display: flex;
    padding: 20px;
    
    border-radius: 5px;
}
.tutor-detail-container .rating {
  margin-bottom: 10px;
}

.tutor-detail-container .rating p {
    margin-left: 5px;
}



.tutor-detail-container .subjects {
    margin-bottom: 10px;
}

.tutor-detail-container .location {
    margin-bottom: 40px;
}

.tutor-detail-container .location, .tutor-detail-container .subjects,
.tutor-detail-container .rating {
    display: flex;
}

.tutor-detail-container .location > img, .tutor-detail-container .subjects > img {
  margin-right: 5px;
}

.tutor-detail-container > .tutor-detail > .profile-info {
    display: flex;
}

.tutor-detail-container > .tutor-detail > .profile-info > .profile-image-wrapper > img {
    height: 243px;
}

.tutor-detail-container .tutor-name {
    color: #009BA7;
}

.tutor-detail-container > .tutor-detail > .profile-info > .info {
    flex-basis: 40%;
    padding: 0 25px;
}

.tutor-detail-container > .tutor-detail > .profile-info > .info p {
    color: #7A7A7A;
}

.tutor-detail-container > .tutor-detail > .profile-info > .info .bio-detail {
    margin-bottom: 20px;
}

.tutor-detail-container > .tutor-detail > .profile-info > .info button {
    background-color: #009BA7;
    color: #FFFFFF;
    padding: 10px 25px;
}

.tutor-detail-container > .tutor-detail > .profile-info > .about-tutor {
  flex-basis: 40%;
  padding: 25px;
  background-color: #D2F1F1;
  border-radius: 5px;
}

.tutor-detail-container > .tutor-detail > .profile-info > .about-tutor > div:first-of-type {
  margin-bottom: 60px;
}

.tutor-detail-container > .tutor-detail > .profile-info > .about-tutor h5 {
  color: #7A7A7A;
}

.tutor-detail-container > .tutor-detail > .profile-info > .about-tutor > div:first-of-type > div:first-of-type {
  margin-bottom: 20px;
}

.tutor-detail-container > .tutor-detail > .profile-info > .about-tutor > div:last-of-type p {
  color: #777474;
}