.fixed-tutor-header {
  position: fixed;
  top: 0;
  height: 58px;
  z-index: 7;
  width: min(100%, 100vw);
  background-color: var(--white);
  border-bottom: 1px solid var(--off-grey);
}

.noti-icon {
  position: relative;
}
.noti-icon .noti-count {
  position: absolute;
  top: 3px;
  right: -3px;
  width: 14px;
  height: 14px;
  background: var(--noti);
  color: var(--white);
  font-weight: 900;
  font-size: 10px;
  line-height: 1;
  border-radius: 100px;
  display: grid;
  place-content: center;
}

/* Menubar */

.tutor-menubar {
  height: 52px;
  position: fixed;
  width: min(100%, 100vw);
  top: 42px;
  background: var(--white);
  z-index: 6;
  border-bottom: 1px solid var(--off-grey);
  display: grid;
  grid-template-columns: 140px 1fr 140px;
  justify-items: center;
  align-content: center;
}
.menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-inline-start: 1rem;
  margin-inline-end: 1rem;
  transition: all .5s ease;
}
.menu-item svg {
  margin-right: .8rem;
}
.menu-item .nav-text {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--off-green);
  text-decoration: none;
}

.menu-item::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -1px;
  height: 4px;
  background: transparent;
  width: 80px;
  transform: scaleX(0);
  transform-origin: center;
  transition: all .5s ease;
}
.menu-item:hover::after,
.menu-item.active::after {
  background-color: var(--primary-darker);
  transform: scaleX(1);
}

@media only screen and (max-width: 768px) {
  .tutor-menubar {
    position: relative;
    display: grid;
    height: auto;
    top: unset;
    grid-template-columns: min-content;
    grid-template-rows: 20px 1fr;
    gap: 30px 20px;
    border-bottom: 0;
    justify-items: flex-start;
    justify-content: flex-start;
    padding-inline-start: 20px;
  }
  .menu-item {
    margin-inline-start: 0;
    margin-block-end: 1rem;
  }
  .menu-item::after {
    content: '';
    all: unset;
  }
  .menu-item .nav-text {
    font-size: 16px;
    line-height: 20px;
  }
}