.caraosel-testimonial {
  width: min(920px, 100%);
  margin: 2.5rem auto;
}

.t-card {
  background-color: #F6F6F6;
  border-radius: 12px;
  position: relative;
}
.left-image img{
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.right-content {
  padding: 4.5rem;
}
.member {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #000;
}
.role {
  font-size: 20px;
  line-height: 24px;
  color: #3D3D3D;
}
.comment {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #303030;
  width: min(292px, 100%);
  margin: 3rem 0;
}
.quote-image {
  width: 60px;
  height: 60px;
  position: absolute;
  top: -30px;
  right: 50px;
}

@media only screen and (max-width: 768px) {
  .right-content {
    padding: 1.5rem;
  }

  .left-image img{
    padding-top: 2rem;
    width: 260px;
    margin: 0 auto;
    height: 300px;
    border-radius: 12px;
  }
  
}