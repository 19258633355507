.fixed-header {
  position: fixed;
  top: 0;
  height: 63px;
  z-index: 7;
  width: min(100%, 100vw);
  background-color: var(--white);
  box-shadow: 0 3px 15px rgba(0, 0, 0, .2);
}
.bx-none {
  box-shadow: none;
}
.bx-small {
  box-shadow: 0 0 2px rgba(0, 0, 0, .2);
}
.logo-wrapper img, .logo-wrapper svg{
  width: 110px;
  height: auto;
}

ul.navigation-links {
  list-style: none;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

ul.navigation-links > li {
  font-size: 15px;
  font-weight: 600;
  line-height: 32px;
  color: var(--black);
  position: relative;
  margin: 0 20px;
  cursor: pointer;
  transition: all .5s ease;
}

ul.navigation-links > li:hover,
ul.navigation-links > li.active {
  color: var(--primary);
}
ul.navigation-links > li a:hover{
  text-decoration: none;
}
ul.navigation-links > li::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -16px;
  height: 4px;
  background: transparent;
  width: 100%;
  transform: scaleX(0);
  transform-origin: center;
  transition: all .5s ease;
}
ul.navigation-links > li:hover::after,
ul.navigation-links > li.active::after {
  background-color: var(--primary);
  transform: scaleX(1);
}

.mobile-header {
  display: none;
}

@media only screen and (max-width: 768px) {
  .desktop-header {
    display: none;
  }
  .mobile-header {
    display: flex;
  }
  .fixed-header {
    padding: 5px 20px;
  }
  ul.navigation-links {
    width: 100%;
    display: block;
  }
  ul.navigation-links > li {
    margin: 10px;
    font-size: 18px;
    cursor: pointer;
    position: relative;
  }
  ul.navigation-links > li:hover::after,
  ul.navigation-links > li.active::after {
    background-color: transparent;
    transform: scaleX(0);
  }
  .mobile-nav {
    z-index: 9;
    position: fixed;
    width: 80%;
    height: 100vh;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    background-color: #fff;
    transition: all 0.2s ease-out;
  }
  .mobile-nav.show {
    transform: translateX(0);
  }
  .mobile-nav--close {
    height: 63px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.1);
  }
  .nav-wrapper {
    height: calc(100vh - 63px);
    padding: 2rem;
    overflow: hidden;
    overflow-y: auto;
  }
  .mobile-header--overlay {
    position: fixed;
    z-index: 8;
    top: 0;
    left: 0;
    height: max(100%, 100vh);
    width: max(100%, 100vw);
    background-color: rgba(0,0,0,0.75);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }
  .mobile-header--overlay.show {
    opacity: 1;
    visibility: visible;
  }
}

/***************** AUTH NAVBAR & SIDEBAR ******************/

.icon-sidebar {
  position: fixed;
  top: 63px;
  left: 0;
  width: 82px;
  height: calc(100vh - 63px);
  background-color: var(--white);
}
.icon-sidebar.list-style {
  position: relative;
  all: unset;
  width: 100%;
}
.icon-sidebar.list-style .sidebar-nav-item {
  width: 100%;
  height: auto;
  margin-bottom: 1.4rem;
  grid-template-columns: .2fr 1fr;
  gap: 1rem;
  justify-items: unset;
  align-content: unset;
  justify-content: center;
  align-items: center;
}
.icon-sidebar.list-style .sidebar-nav-item .nav-text {
  font-size: 20px;
  line-height: 26px;
  margin: 0;
}

.sidebar-nav-item {
  position: relative;
  width: 82px;
  height: 82px;
  padding: 0;
  display: grid;
  justify-items: center;
  align-content: center;
  text-decoration: none;
}
.nav-text {
  margin-top: .5rem;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  color: #B8B8B8;
  text-decoration: none;
}
.sidebar-nav-item:hover {
  text-decoration: none;
}
.sidebar-nav-item:hover svg path, .sidebar-nav-item.active svg path {
  fill: var(--primary);
}
.sidebar-nav-item:hover .nav-text, .sidebar-nav-item.active .nav-text {
  color: var(--primary);
}
