.tutor-review > div {
    display: flex;
}

.tutor-review > div > .reviews-container {
    flex-basis: 70%;
    padding: 0 20px;
}

.tutor-review > div > .reviews-container .review {
    margin-bottom: 40px;
    padding: 20px 40px;
    background-color: #fff;
}

.tutor-review > div > .reviews-container .review h4 {
    font-size: 18px;
    margin-bottom: 0;
}

.tutor-review > div > .reviews-container .review > div {
    display: flex;
    justify-content: space-between;
}

.tutor-review > div > .reviews-container .review h5 {
    font-size: 16px;
    color: #7A7A7A;
}

.tutor-review > div > .reviews-container .review:last-of-type {
    margin-bottom: 0;
}

.tutor-review > div > .reviews-container p {
    text-align: justify;
    max-width: 70%;
}

.tutor-review > div > .rating-info {
    flex-basis: 30%;
    padding: 0 20px;
}

.tutor-review > div > .rating-info > .average-rating-container {
    background-color: #fff;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.tutor-review > div > .rating-info .number-rating {
    width: 100%;
}

.tutor-review > div > .rating-info .number-rating > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tutor-review > div > .rating-info .number-rating > div > * {
    margin-right: 2px;
}

.tutor-review > div > .rating-info .number-rating > div h5 {
    color: #7A7A7A;
}

.tutor-review > div > .rating-info .number-rating > div .MuiLinearProgress-root {
    width: 100%;
}

.tutor-review > div > .rating-info .number-rating > div > .MuiRating-root {
    font-size: 1.2rem;
}

.tutor-review > div > .rating-info .number-rating > div > .MuiRating-root > .MuiRating-label {
    margin-bottom: 0;
}

.tutor-review > div > .rating-info .number-rating > div > .MuiRating-root .MuiRating-icon {
    color: #7A7A7A;
}

.tutor-review > div > .rating-info .number-rating > div .MuiLinearProgress-colorPrimary {
    background-color: #AFAFAF;
}

.tutor-review > div > .rating-info .number-rating > div .MuiLinearProgress-barColorPrimary {
    background-color: #5CE0EB;
}

.tutor-review > div > .rating-info > .rating-chart {
    background-color: #fff;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tutor-review > div > .rating-info > .rating-chart > h4 {
    margin-bottom: 20px;
}

.tutor-review > div > .rating-info > .rating-chart > div >div {
    display: flex;
}

.tutor-review > div > .rating-info > .rating-chart > div >div h5 {
    color: #7A7A7A;
    width: 45%;
}

.tutor-review > div > .rating-info > .rating-chart > div >div > .MuiRating-root {
    width: 55%;
}