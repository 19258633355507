.tutor-detail-container {
    background-color: #F9F9F9;
    min-height: 100vh;
    overflow-x: hidden;
    padding: 0 40px;
}

.tutor-detail-container h3 {
    font-size: 20px;
    color: #2A2A2A;
}

.tutor-detail-container h5 {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: bolder;
    color: #2A2A2A;
}
.tutor-detail-container p {
    font-size: 16px;
    color: #2A2A2A;
}

.tutor-detail-container h4 {
    font-size: 16px;
    color: #2A2A2A;
}

.tutor-detail-container h4.title {
    font-weight: bolder;
}

.tutor-detail-container > .header {
    height: 102px;
    display: grid;
    grid-template-columns: 110px 1fr max-content;
    padding: 1rem 1.5rem 1rem 2rem;
  }

  .tutor-detail-container .MuiRating-root .MuiRating-icon {
    color: #ffb400;
}

.tutor-detail-container .MuiRating-root .MuiRating-icon.MuiRating-iconEmpty {
  color: #D8D8D8;
}
